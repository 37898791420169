import React, { FC, useContext, useEffect } from 'react'
import { navigate } from '@components/ui/link'
import config from '@utils/config'
import { useAccount, useMsal } from '@azure/msal-react'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { User } from '@services/models'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import { setRequestListStore } from '@services/store/requestList'
import { useAppDispatch } from '@services/store'

type Classes = {
	root: string
	header: string
	loader: string
	loaderContainer: string
	placeholder: string
}

const classes: Classes = makeClasses({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		maxWidth: '100%',
		height: '100vh'
	},
	header: {
		width: '100%',
		background: Colors.primary,
		height: '150px'
	},
	loaderContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center'
	},
	loader: {
		border: `4px solid ${Colors.lightGrey}`,
		borderTop: `4px solid ${Colors.blue}`,
		borderRadius: '50%',
		width: '26px',
		height: '26px',
		margin: '15px',
		animation: 'spin 2s linear infinite'
	},
	placeholder: {
		height: '100px'
	}
})

const LoginRedirect: FC = () => {
	const { accounts } = useMsal() ?? []
	const account = useAccount(accounts[0] || {})
	const { setAuthUser, pageData } = useContext(AppStateContext)

	const authRedirect: string = config.localStorage.authRedirect
	const link: string | null = localStorage.getItem(authRedirect)
	const dispatch = useAppDispatch()

	const fetchUser = async () => {
		if (account) {
			try {
				const user = await User.buildAsync(account)
				setAuthUser(user)

				dispatch(
					setRequestListStore({
						fetching: false,
						fetched: true,
						requests: user.requests
					})
				)

				localStorage.removeItem(authRedirect)

				setTimeout(() => {
					if (link) {
						navigate(link)

						return
					}

					navigate(config.homepage.baseURL)
				}, 50)
			} catch (error: any) {
				navigate(config.auth.baseUrl)
				console.error(error?.message)
			}
		}
	}

	useEffect(() => {
		fetchUser()
	}, [])

	return (
		<div className={classes.root}>
			<div className={classes.header}></div>
			<div className={classes.loaderContainer}>
				<div className={joinClasses([classes.loader, 'spin'])} />
				<span style={{ fontSize: '1rem' }}>{pageData?.assets?.loading}</span>
			</div>
			<div className={classes.placeholder}></div>
		</div>
	)
}

export default LoginRedirect
